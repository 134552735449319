<template>
  <div class="wrapper flex fd-c ai-c">
      <img class="banner" src="/img/group.png" alt="">
      <div class="box" ref="box">
          <div class="navbox" :class="fixed?'fixed':''" :style="{'background-color':fixed?'rgba(255, 255, 255,'+opacity+')':''}">
                <div class="nav flex ai-c">
                    <div class="nav-item" 
                    v-for="(item, index) in nav" 
                    @click="navClick(item)"
                    :class="acitve==item.status&&'acitve'"
                    :key="index">{{item.name}}</div>
                </div>
            </div>
      </div>
      <van-list
        :finished="finished"
        v-model="loading"
        @load="onLoad" 
        class="list flex fd-c ai-c">
          <template v-for="(item, index) in groupList">
              <v-groupGoods :goods="item" :isNumberHide="true" :key="index" />
          </template>
          
      </van-list>
  </div>
</template>

<script>
import groupGoods from '@/components/groupGoods'
import { marketPage } from '../../../api'
export default {
  name: "groupList",
  components:{
      'v-groupGoods': groupGoods
  },
  props:{},
  data(){
    return {
        nav: [{
            name: '正在团',
            status: 1
        },{
            name: '已结束',
            status: 2
        }],
        acitve: 1,
        fixed: false,
        opacity: 1,
        current: 1,
        timer: null,
        groupList: [],
        loading: false,
        finished: false,
    }
  },
  watch:{},
  computed:{},
  methods:{
      onLoad() {
          this.marketPage()
      },
      fixedNav() {
          var offsetTop = this.$refs.box.offsetTop
          var scrollTop = document.documentElement.scrollTop;
          this.opacity = (scrollTop-offsetTop)/40
          scrollTop >=offsetTop ? this.fixed = true : this.fixed = false
      },
      navClick(e){
          if (this.acitve != e.status) {
              this.acitve = e.status
              this.groupList = []
              clearInterval(this.timer)
              this.current = 1
              this.marketPage()
          }
          
      },
      //拼团列表
      marketPage() {
          this.$toast.loading({
              forbidClick: true,
              duration: 0
          })
          marketPage({
              status: this.acitve,
              current: this.current,
              size: 20,
          }).then(res=>{
              var list = res.data.data.records
              // 加载状态结束
              this.loading = false;
              this.current++
              for (var item of list) {
                item.date = this.countDownTime2(item.endTime.replace(/-/g, '/'),'day',this.currentTime())
              }
              this.groupList = this.groupList.concat(list)
              this.timer = setInterval(()=>{
                for (var item of this.groupList) {
                    item.date = this.countDownTime2(item.endTime.replace(/-/g, '/'),'day',this.currentTime())
                }
                //  console.log(this.groupList);
              },1000)
                if (list.length == 0) {
                    this.finished = true;
                } else {
                    this.finished = false;
                }   
          })
          .finally(()=>{
              this.$toast.clear()
          })
      },
      currentTime() {
        function nb(data){
            return data<10?'0'+data:data
        }
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth()+1;//得到月份
        var date = now.getDate();//得到日期
        // var day = now.getDay();//得到周几
        var hour= now.getHours();//得到小时数
        var minute= now.getMinutes();//得到分钟数
        var second= now.getSeconds();//得到秒数
        return year + '/' + nb(month) + '/' + nb(date) + ' ' + nb(hour) + ':' + nb(minute) + ':' + nb(second)
      },
      countDownTime2(endTime, maxUnit = "day", startTime) {
            let end = new Date(endTime);
            let start = startTime ? new Date(startTime) : new Date();
            if (start - end > 0) {
                // throw new Error("开始时间不能晚于结束时间")
                return {
                    day: 0,
                    hour: 0,
                    minute: 0,
                    month: 0,
                    second: 0,
                    year: 0
                }
            }
            let aUnitArr = [
                {
                    value: "second",
                    interval: 60,
                    secNum: 1 //该单位有多少秒，计算该单位最大差值用到
                },
                {
                    value: "minute",
                    interval: 60,
                    secNum: 60
                },
                {
                    value: "hour",
                    interval: 24,
                    secNum: 60 * 60
                },
                {
                    value: "day",
                    secNum: 60 * 60 * 24
                },
                {
                    value: "month",
                    interval: 12
                },
                {
                    value: "year",
                },
            ]
            let endList = this.getTimeList(end);
            let startList = this.getTimeList(start);
            const iMaxIndex = aUnitArr.findIndex(item => maxUnit === item.value);
            // 当最大单位为日时分秒时过滤。月份最大单位需根据年份反算所以不能过滤掉年份
            if (iMaxIndex > -1 && iMaxIndex < 4) {
                aUnitArr = aUnitArr.filter((item, index) => iMaxIndex > -1 && index <= iMaxIndex);
            }
            let result = {};
            aUnitArr.forEach((item, index) => {
                if (index === iMaxIndex && iMaxIndex < 4) {
                    result[item.value] = Math.floor((end - start) / item.secNum / 1000);
                    return
                }
                if (endList[index] - startList[index] >= 0) {
                    result[item.value] = endList[index] - startList[index];
                } else {
                    endList[index + 1]--;
                    result[item.value] = item.value === "day" ? 
                    	this.countDiffDays(start, startList[index], endList[index]) : endList[index] + item.interval - startList[index];
                }
            })
            // 最大单位是月份时特殊处理
            if (maxUnit === "month") {
                result.month += result.year * 12
                delete result.year
            }
            return result;
        },
         getTimeList(t) {
            return [t.getSeconds(), t.getMinutes(), t.getHours(), t.getDate(), t.getMonth() + 1, t.getFullYear()];
        },
        countDiffDays(time, startDay, endDay) {
            let curDate = new Date(time);
            let curMonth = curDate.getMonth();
            /* 这里将时间设置为下个月之前，需要把日期设置小一点，否则极端情况，如果当天日期大于下一个月的总天数，月份会设置为下下个月 */
            curDate.setDate(1)
            curDate.setMonth(curMonth + 1);
            curDate.setDate(0);//日期设置为前一个月的最后一天
            let restDays = curDate.getDate() - startDay;
            return restDays + endDay;
        }
  },
  created(){
      
  },
  mounted(){ 
    window.addEventListener('scroll', this.fixedNav, false);
  },
  beforeDestroy() { // 在组件生命周期结束的时候销毁。
    window.removeEventListener('scroll', this.fixedNav, false)
    clearInterval(this.timer)
  }
}
</script>
<style scoped>
.wrapper{
    width: 100%;
    min-height: 100vh;
    background-color: rgba(248, 176, 115, 1);
}
.banner{
    width: 100%;
    height: 2.27rem;
    object-fit:cover;
}
.fixed{
    position: fixed;
    top: 0;
    padding: 0 .4rem!important;
    border-radius: 0!important;
}
.box{
    width: 7.1rem;
    height: .74rem;
    margin-top: .18rem;
}
.navbox{
    top: 0;
    left: 0;
    z-index: 100;
    width: 6.7rem;
    height: .74rem;
    background: #FFFFFF;
    border-radius: .10rem;
    overflow: hidden;
    padding: 0 .2rem;
}
.nav{
    width: 100%;
    height: .74rem;
    overflow-y: scroll;
}
.nav .nav-item{
    position: relative;
    width: 50%;
    text-align: center;
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    white-space: nowrap;
}
.nav .acitve{
    font-size: .30rem;
    font-weight: 800;
    color: #F96F30;
    margin-right: .4;
}
.nav .acitve::before{
    content: '';
    position: absolute;
    left: calc(50% - .58rem);
    bottom: -.08rem;
    width: 1.16rem;
    height: .04rem;
    background: #F96F30;
    border-radius: .02rem;
}
.list{
    margin-top: .35rem;
}
.list .title{
    width: 2.54rem;
    background-image: url(/img/2020@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    font-size: .34rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: .25rem;
}
</style>