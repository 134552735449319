<template>
  <div class="groupGoods">
      <div class="user-box flex ai-c jc-sb">
          <div class="user-l flex ai-c">
              <img :src="goods.headAvatar" alt="">
              <div>
                  <div class="name line">{{goods.headName}}</div>
                  <div class="time">{{getDateDiff(goods.startTime)}} | {{goods.buyerCount}}人参团</div>
              </div>
          </div>
          <div class="share-btn flex ai-c jc-c">
              分享
              <img src="/img/icon-share.png" alt="">
          </div>
      </div>
      <div class="goods-name lines">{{goods.marketText}}</div>
      <div class="money">{{goods.marketPrice}}</div>
      <div class="goods-list flex ai-c">
          <img v-for="(item, index) in goods.marketPic.split(',')" @click="preViewClick(index, goods.marketPic.split(','))" :key="index" :src="item" alt="">
      </div>
      <van-notice-bar :scrollable="false" background="#fff" style="margin-top: .2rem">
        <van-swipe
            vertical
            class="notice-swipe"
            :touchable="false"
            :autoplay="4000"
            :show-indicators="false"
        >
            <template v-for="(item, index) in goods.buyerList">
                <van-swipe-item v-if="index<goods.buyerList.length/3" :key="index">
                    <div class="list">
                        <template v-for="(im, ix) in goods.buyerList">
                            <div v-if="index*3<=ix&&ix<(index+1)*3"  class="item flex jc-sb" :key="ix">
                                <div class="t">{{im.buyerName}}</div>
                                <div class="t t1">{{getDateDiff(im.createTime)}}</div>
                                <div class="t t2 line">{{im.goodsName}}</div>
                                <div class="nb">+{{im.goodsQuantity}}</div>
                            </div>
                        </template>
                    </div>
                </van-swipe-item>
            </template>
        </van-swipe>
      </van-notice-bar>
      <div class="timer-box flex ai-c jc-sb">
          <div class="timer-box-l">
              <!-- <div class="timer-t">距活动结束还有：</div> -->
              <div class="timer flex ai-c">
                  <div class="txt">剩</div>
                  <div class="nb">{{goods.date.day}}</div>
                  <div class="txt">天</div>
                  <div class="nb">{{goods.date.hour}}</div>
                  <div class="txt">:</div>
                  <div class="nb">{{goods.date.minute}}</div>
                  <div class="txt">:</div>
                  <div class="nb">{{goods.date.second}}</div>
                  <div class="txt">结束</div>
              </div>
          </div>
          <div class="btn" @click="$router.push({path:'/home/groupDetails',query: {id: goods.id}})">立即参团</div>
      </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  components:{},
  props:["goods"],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{
      preViewClick(index,imgs){
        ImagePreview({
            images: imgs,
            startPosition: index,
        })
      },
        getDateDiff(dateTimeStamp){
                dateTimeStamp = Date.parse(dateTimeStamp.replace(/-/gi,"/"))
                var minute = 1000 * 60;
                var hour = minute * 60;
                var day = hour * 24;
                var halfamonth = day * 15;
                var month = day * 30;
                var now = new Date().getTime();
                var diffValue = now - dateTimeStamp;
                if(diffValue < 0){return;}
                var monthC =diffValue/month;
                var weekC =diffValue/(7*day);
                var dayC =diffValue/day;
                var hourC =diffValue/hour;
                var minC =diffValue/minute;
                var result = ''
                if(monthC>=1){
                    result="" + parseInt(monthC) + "月前";
                }
                else if(weekC>=1){
                    result="" + parseInt(weekC) + "周前";
                }
                else if(dayC>=1){
                    result=""+ parseInt(dayC) +"天前";
                }
                else if(hourC>=1){
                    result=""+ parseInt(hourC) +"小时前";
                }
                else if(minC>=1){
                    result=""+ parseInt(minC) +"分钟前";
                }else
                result="刚刚";
                return result;
        }
  },
  created(){},
  mounted(){}
}
</script>
<style scoped>
.groupGoods{
    width: 7.1rem;
    background-color: #fff;
    border-radius: .14rem;
    overflow: hidden;
    margin-bottom: .25rem;
}
.groupGoods .user-box {
    margin-top: .2rem;
}
.groupGoods .user-box .user-l img{
    width: .94rem;
    height: .94rem;
    background: #FFFFFF;
    border: .02rem solid #EDEDED;
    border-radius: .47rem;
    margin-left: .15rem;
    margin-right: .05rem;
}
.groupGoods .user-box .user-l .name{
    width: 1.6rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
}
.groupGoods .user-box .user-l .time{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #505050;
}
.groupGoods .user-box .share-btn{
    width: 1.36rem;
    height: .52rem;
    background: #FFFFFF;
    border: .02rem solid #F96F30;
    border-radius: .26rem;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F96F30;
    margin-right: .26rem;
}
.groupGoods .user-box .share-btn img{
    width: .26rem;
    height: .24rem;
    margin-left: .05rem;
}
.goods-name{
    width: 100%;
    padding: 0 .25rem;
    box-sizing: border-box;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 800;
    color: #282828;
    margin-top: .35rem;
}
.money{
    font-size: .40rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF3604;
    margin-left: .25rem;
}
.money::before{
    content: '¥';
    font-size: .3rem;
    color: #FF3604;
}
.goods-list{
    margin-left: .1rem;
}
.goods-list img{
    width: 2.1rem;
    height: 2.10rem;
    border-radius: .1rem;
    margin: 0 .1rem;
    object-fit: contain;
}
.van-notice-bar{
    height: 1.6rem;
}
.notice-swipe {
    width: 100%;
    height: 1.6rem;
}
.groupGoods>>>.van-notice-bar__content.van-ellipsis{
    width: 100%;
}
.list{
    width: 100%;
}
.list .item {
    width: 100%;
    /* margin-bottom: .15rem; */
    align-items: center;
}
.list .item .t{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #505050;
    line-height: .28rem;
}
.list .item .nb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FF3604;
}
.list .item .t1{
    width: 1.2rem;
    margin-left: .5rem;
}
.list .item .t2{
    width: 3rem;
    margin-left: .2rem;
}
.timer-box{
    /* margin-top: .2rem; */
    margin-bottom: .5rem;
}
.timer-box-l{
    margin-left: .26rem;
}
.timer-box .timer-t{
    font-size: .25rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF441A;
}
.timer .nb{
    width: .40rem;
    height: .445rem;
    line-height: .445rem;
    text-align: center;
    background: rgba(242, 72, 27, 1);
    /* border: 1px solid #FF441A; */
    border-radius: .10rem;
    font-size: .25rem;
    font-family: PingFang;
    font-weight: bold;
    color: #fff;
    box-sizing: border-box;
    margin-right: .05rem;
}
.timer .txt{
    font-size: .25rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-right: .05rem;
}
.timer-box .btn {
    width: 2.98rem;
    height: .64rem;
    line-height: .64rem;
    text-align: center;
    background: #F96F30;
    border-radius: .32rem;
    margin-right: .25rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
}
</style>